import { SIGNUP_WEB_CHANNEL, SIGNUP_WEB_IDENTIFIER } from '../../constants/general';
import { Events } from '../../enums/events';
import { TrackersArgs, TrackHelpViewedArgs, TrackHelpArticleExpandedArgs } from '../../types/track';
import { lsSegmentService } from './segment';

export const populateCommonData = (payload: TrackersArgs) => {
  payload.customer_signup_version = SIGNUP_WEB_IDENTIFIER;
  payload.channel = SIGNUP_WEB_CHANNEL;
};

export const track = (event: Events, properties: TrackersArgs) => {
  populateCommonData(properties);
  lsSegmentService.track(event, properties);
};

// Trackers
export const trackHelpViewed = (properties: TrackHelpViewedArgs) => track(Events.HelpViewed, properties);
export const trackHelpArticleExpanded = (properties: TrackHelpArticleExpandedArgs) =>
  track(Events.HelpArticleExpanded, properties);
