import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { StyledIcon } from './styles';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { useCallback } from 'react';
import { lsI18NService } from '../../../../../service';
import { trackHelpViewed } from '../../../../../service/segment/trackers';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';
import { Events } from '../../../../../enums/events';

export const CompactFaq = () => {
  const { colors, spacing } = useAppTheme();
  const modal = useModal(ModalTemplate.GENERIC);
  const { payloadBuilder } = useTrackPayload(Events.HelpViewed);

  const openModal = useCallback(() => {
    const payload = payloadBuilder({ helpContext: 'payment' });
    payload && trackHelpViewed(payload);

    modal.show({
      animationType: 'fade',
      content: () => (
        <>
          <Text variant="headlineMedium">{lsI18NService.t(`payment.faq.question1.title`)}</Text>
          <br />
          <Text variant="bodyMedium">{lsI18NService.t(`payment.faq.question1.content`)}</Text>
        </>
      ),
      buttonText: lsI18NService.t('close'),
    });
  }, [modal, payloadBuilder]);

  return (
    <StyledIcon onClick={openModal} data-testid="payment-faq-mobile" className="md:hidden">
      <Icon name="help-circle-outline" color={colors.primary} size={spacing.s7} />
    </StyledIcon>
  );
};
