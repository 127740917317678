import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useProspect } from './useProspect';
import { Place } from '@lawnstarter/ls-react-common/types';
import { useSelector } from 'react-redux';
import { selectApp } from '../../../store/modules/app/slice';
import { CAPTCHA_ACTIVATED, LOADING_DELAY_IN_MS } from '../../../constants/general';
import { usePlaces } from '../../../hooks/usePlaces';
import { formatPlace } from '@lawnstarter/ls-react-common/helpers';
import { QueryString } from '../../../enums/storage';
import { InvalidPhoneError } from '../../../constants/GraphQLErrors';

const defaultValues = {
  name: '',
  address1: '',
  address2: '',
  phone: '',
};

export const useContactInfo = () => {
  const [searchParams] = useSearchParams();
  const nameParam = searchParams.get(QueryString.Name);
  const phoneParam = searchParams.get(QueryString.Phone);
  const addressParam = searchParams.get(QueryString.Address);
  const placeIdParam = searchParams.get(QueryString.GooglePlaceId);
  const dealParam = searchParams.get(QueryString.PromoCode);
  const segmentParam = searchParams.get(QueryString.SegmentId);
  const { turnstileToken } = useSelector(selectApp);
  const { control, handleSubmit, setValue, getValues, formState, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      name: nameParam || defaultValues.name,
      phone: phoneParam || defaultValues.phone,
    },
  });

  const { handleProspect, data, isSuccess, isLoading, isError, error, isUninitialized, reset } = useProspect();
  const [place, setPlace] = useState<Place>();
  const [isAutofill, setIsAutofill] = useState(false);
  const [delayIsOver, setDelayIsOver] = useState(false);
  const { prediction, getPlaceByInput, getPlaceById, isReady } = usePlaces();

  // Handle validation errors
  useEffect(() => {
    const { message, error: phoneError } = InvalidPhoneError;
    const isInvalidPhoneNumber = error?.message?.includes(phoneError);

    isInvalidPhoneNumber && setError('phone', { type: 'error', message });
  }, [error, setError]);

  /**
   * AutoFill process: if the user comes from anywhere contianing name, phone, and address as queryString the handle will be dispatched
   */
  useEffect(() => {
    if (nameParam && phoneParam && addressParam) {
      setIsAutofill(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAutofillReady = CAPTCHA_ACTIVATED
      ? isAutofill && isReady && isUninitialized && turnstileToken
      : isAutofill && isReady && isUninitialized;

    if (!isAutofillReady) return;

    if (placeIdParam) {
      getPlaceById(placeIdParam);
    } else if (addressParam) {
      getPlaceByInput(addressParam);
    }
  }, [addressParam, getPlaceById, getPlaceByInput, isAutofill, isReady, isUninitialized, placeIdParam, turnstileToken]);

  useEffect(() => {
    if (isAutofill && isReady && isUninitialized && prediction && nameParam && phoneParam) {
      setTimeout(() => setDelayIsOver(true), LOADING_DELAY_IN_MS);

      const { street, city, state, country, zip, complement } = formatPlace({ place: prediction });

      handleProspect({
        name: nameParam,
        phone: phoneParam,
        promoCode: dealParam,
        segmentAnonymousId: segmentParam,
        address1: street,
        address2: complement || '',
        city,
        state,
        zip,
        country,
      });
    }
  }, [
    addressParam,
    dealParam,
    getPlaceByInput,
    handleProspect,
    isAutofill,
    isReady,
    isUninitialized,
    nameParam,
    phoneParam,
    prediction,
    segmentParam,
  ]);

  /**
   * When the user submit the form
   */
  const onSubmit = useCallback(async () => {
    return new Promise((resolve, reject) => {
      handleSubmit(
        (formInput) => {
          setTimeout(() => setDelayIsOver(true), LOADING_DELAY_IN_MS);
          place && resolve(handleProspect({ ...place, ...formInput }));
        },
        () => {
          reject('Invalid data');
        },
      )();
    });
  }, [handleProspect, handleSubmit, place]);

  /**
   * When the user select an address from input
   */
  const onSetAddress = useCallback((params: { place: Place }) => {
    setPlace(params.place);
  }, []);

  /**
   * It ensures address can't be an empty string
   */
  const setAddressInput = useCallback(
    (_text: string) => {
      const address = getValues('address1');
      if (!address) {
        setValue('address1', '');
      }
    },
    [getValues, setValue],
  );

  return {
    delayIsOver,
    onSubmit,
    onSetAddress,
    setAddressInput,
    form: {
      control,
      isValid: formState.isValid,
    },
    mutation: {
      data,
      isLoading,
      isSuccess,
      isError,
      error,
      isUninitialized,
      isAutofill,
      reset,
    },
  };
};
