import { useAppTheme } from '@lawnstarter/ls-react-common';
import { DatePicker as DatePickerCommon, DatePickerProps } from '@lawnstarter/ls-react-web-common';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import './style.css';
import { useState } from 'react';

export const DatePicker = (props: DatePickerProps) => {
  const [open, setOpen] = useState(false);
  const { colors } = useAppTheme();
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <DatePickerCommon
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        slotProps={{ textField: { onClick: () => setOpen(!open) } }}
      />
    </ThemeProvider>
  );
};
