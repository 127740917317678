const {
  VITE_APP_ENV,
  VITE_LEGACY_SIGNUP_PORTAL,
  VITE_LEGACY_MY_PORTAL,
  VITE_SENTRY_ENABLED,
  VITE_SENTRY_DSN,
  VITE_SEGMENT_KEY,
  VITE_BRAND,
  VITE_API_BASE_URL,
  VITE_GOOGLE_API_KEY,
  VITE_STRIPE_API_KEY,
  VITE_TURNSTILE_KEY,
  VITE_TURNSTILE_ON,
  VITE_MY_LOGIN_URL,
} = import.meta.env;

export {
  VITE_APP_ENV,
  VITE_LEGACY_SIGNUP_PORTAL,
  VITE_LEGACY_MY_PORTAL,
  VITE_SENTRY_ENABLED,
  VITE_SENTRY_DSN,
  VITE_SEGMENT_KEY,
  VITE_BRAND,
  VITE_API_BASE_URL,
  VITE_GOOGLE_API_KEY,
  VITE_STRIPE_API_KEY,
  VITE_TURNSTILE_KEY,
  VITE_TURNSTILE_ON,
  VITE_MY_LOGIN_URL,
};
