import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../..';

interface AppState {
  shouldShowHeader: boolean;
  headerRightSlot: 'address' | null;
  turnstileToken: string | null;
  segmentAnonymousId: string | null;
  error: string | null;
}

const initialState: AppState = {
  shouldShowHeader: true,
  headerRightSlot: null,
  turnstileToken: null,
  segmentAnonymousId: null,
  error: null,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateApp(state, { payload }: PayloadAction<Partial<typeof initialState>>) {
      return { ...state, ...payload };
    },
  },
});

export const { updateApp } = slice.actions;
export default slice.reducer;
export const selectApp = (state: RootState) => state.app;
