import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Faq as FaqCommon } from '@lawnstarter/ls-react-common/molecules';
import { ReactNode } from 'react';
import { lsI18NService } from '../../service';

export const Faq = (props: { children: ReactNode }) => {
  const { colors, spacing } = useAppTheme();

  return (
    <FaqCommon
      header={lsI18NService.t('faq')}
      style={{
        wrapper: {
          borderColor: colors.gray.secondary,
          borderRadius: spacing.s2,
          borderStyle: 'solid',
          borderWidth: 1,
          flex: 1,
          paddingHorizontal: spacing.s5,
          paddingVertical: spacing.s3,
        },
        wrapperScroll: {
          height: 300,
        },
      }}
    >
      <>{props.children}</>
    </FaqCommon>
  );
};
