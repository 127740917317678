import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCookie } from 'react-use-cookie';
import { lsSegmentService } from '../..';
import { APP_NAME } from '../../../constants/general';
import { version } from '../../../../package.json';
import { Cookie, QueryString } from '../../../enums/storage';

interface useDocumentTitleArgs {
  userId?: Number;
  traits?: Record<string, unknown>;
}

export function useSegmentIdentify(args?: useDocumentTitleArgs) {
  const [searchParams] = useSearchParams();
  const segid = searchParams.get(QueryString.SegmentId);
  const segmentAnonymousId = getCookie(Cookie.SegmentAnonymousId);
  const targetSegid = segid || segmentAnonymousId;
  useEffect(() => {
    if (targetSegid) {
      lsSegmentService.setAnonymousId(targetSegid);
      return;
    }

    const targetPayload = {
      appName: APP_NAME,
      appVersion: version,
      ...(args?.userId && { userId: args?.userId }),
      ...(args?.traits && { traits: args?.traits }),
    };

    lsSegmentService.identify(targetPayload);
  }, [args, targetSegid]);
}
