import { useCallback } from 'react';
import { getCookie } from 'react-use-cookie';

import { VITE_BRAND } from '../../../config/env';
import { useCreateProspectMutation } from '../../../store/modules/prospect/api';
import { useSchema } from '../../../hooks/useSchema';
import { updateProperty } from '../../../store/modules/property/slice';
import { store } from '../../../store';
import { updateContact } from '../../../store/modules/contact/slice';
import { useSelector } from 'react-redux';
import { selectApp } from '../../../store/modules/app/slice';
import { MutationCreateProspectArgs } from '../../../types/graphql';
import { useSearchParams } from 'react-router-dom';
import { Cookie, QueryString } from '../../../enums/storage';

export const useProspect = () => {
  const { vertical } = useSchema();
  const [createProspect, { data, isSuccess, isLoading, isUninitialized, isError, error, reset }] =
    useCreateProspectMutation();
  const { turnstileToken } = useSelector(selectApp);
  const [searchParams] = useSearchParams();

  const utmSource = searchParams.get(QueryString.UtmSource);
  const utmMedium = searchParams.get(QueryString.UtmMedium);
  const utmTerm = searchParams.get(QueryString.UtmTerm);
  const utmContent = searchParams.get(QueryString.UtmContent);
  const utmCampaign = searchParams.get(QueryString.UtmCampaign);
  const gclid = searchParams.get(QueryString.GoogleId);
  const fbcid = searchParams.get(QueryString.FacebookId);

  const handleProspect = useCallback(
    (input: Partial<MutationCreateProspectArgs>) => {
      const prospectMeta = {
        brandId: VITE_BRAND,
        services: [vertical],
        turnstileToken,
        promoCode: input.promoCode,
        segmentAnonymousId: input.segmentAnonymousId || getCookie(Cookie.SegmentAnonymousId),
        primaryIntent: vertical,
      };

      const propertyInput = {
        phone: String(input.phone).replace(/\D/g, ''),
        name: String(input.name),
        address1: String(input.address1).split(',')[0],
        address2: String(input.address2),
        city: String(input.city),
        state: String(input.state),
        zip: String(input.zip),
        country: String(input.country),
      };

      const contactInput = {
        phone: String(input.phone).replace(/\D/g, ''),
        name: String(input.name),
      };

      const marketingInput = {
        utmSource,
        utmMedium,
        utmTerm,
        utmContent,
        utmCampaign,
        gclid,
        fbcid,
      };

      // Calls mutation
      createProspect({ ...prospectMeta, ...propertyInput, ...marketingInput });

      // Calls store
      store.dispatch(updateProperty(propertyInput));
      store.dispatch(updateContact(contactInput));
    },
    [vertical, turnstileToken, utmSource, utmMedium, utmTerm, utmContent, utmCampaign, gclid, fbcid, createProspect],
  );

  return { handleProspect, data, isSuccess, isLoading, isUninitialized, isError, error, reset };
};
