import { Accordion } from '@lawnstarter/ls-react-common/atoms';
import { CheckboxOptions } from '@lawnstarter/ls-react-common/molecules';
import { Controller } from 'react-hook-form';
import { TERMS_OF_SERVICE } from './terms';
import { FormProps, FormValues } from './types';
import { StyledList } from './styles';

export const Form = ({ control }: FormProps) => {
  return (
    <>
      {TERMS_OF_SERVICE.map(({ title, value, content }) => {
        return (
          <Accordion
            title={title}
            trackID={value}
            style={{
              titleText: {
                justifyContent: 'flex-start',
                width: 'auto',
              },
            }}
            right={() => (
              <Controller
                control={control}
                name={value as keyof FormValues}
                rules={{ required: true }}
                render={() => (
                  <CheckboxOptions
                    testID={`${value}-checkbox`}
                    control={control}
                    options={[
                      {
                        label: '',
                        value: value,
                      },
                    ]}
                    style={{
                      checkboxContainer: {
                        paddingRight: 0,
                      },
                    }}
                  />
                )}
              />
            )}
            key={value}
          >
            <ul>
              {content.map((itemContent, index) => (
                <StyledList key={index}>{itemContent}</StyledList>
              ))}
            </ul>
          </Accordion>
        );
      })}
    </>
  );
};
