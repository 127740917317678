import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Text } from '@lawnstarter/ls-react-common/atoms';

import { useDynamicSvgImport } from '../../hooks/useDynamicSvgImport';
import { updateApp } from '../../store/modules/app/slice';

import { StyledBody, StyledContent, titleStyle } from './styles';

type DinamicImport = { name: string; isBranded: boolean };

export type ErrorScreenProps = {
  image: DinamicImport;
  title: string;
  shouldDisplaySpinner?: boolean;
};

export const ErrorScreen = (props: ErrorScreenProps) => {
  const { Svg } = useDynamicSvgImport(props.image.name, props.image.isBranded);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateApp({ shouldShowHeader: false }));
    return () => {
      dispatch(updateApp({ shouldShowHeader: true }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBody data-testid="error-screen">
      <Svg />
      <StyledContent>
        <Text variant="headlineSmall" style={titleStyle}>
          {props.title}
        </Text>
      </StyledContent>
      {props.shouldDisplaySpinner && <Spinner size={32} />}
    </StyledBody>
  );
};
