import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { initialState as blankContact, selectContact } from '../store/modules/contact/slice';
import { initialState as blankProperty, selectProperty } from '../store/modules/property/slice';
import { StepId, StepType } from '../enums/schema';

export const verticalSelectionStep = `/${StepType.Cart}/${StepId.VerticalSelection}`;
export const contactInfoStep = `/${StepType.Cart}/${StepId.ContactInfo}`;

export const useCheckValidState = () => {
  const navigate = useNavigate();
  const contact = useSelector(selectContact);
  const property = useSelector(selectProperty);
  const { hashId: prospectId } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    const routeExceptions = [verticalSelectionStep, contactInfoStep, `/${StepType.Cart}`, `/${StepType.Cart}/`, `/`];

    if (routeExceptions.includes(pathname)) return;

    if (isEqual(contact, blankContact) || isEqual(property, blankProperty)) {
      navigate({ pathname: contactInfoStep });
    }
  }, [contact, property, navigate, pathname, prospectId]);
};
