import tw from 'tailwind-styled-components';

export const StyledActionBar = tw.div`
  flex items-center justify-between
`;

export const StyledWrapperPhone = tw.button`
  rounded-full border border-graySecondary p-1 md:hidden
`;

export const StyledSlot = tw.div`
  flex
`;
