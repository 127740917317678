import { lsI18NService } from '../../../service';
import { Step } from '../../../layout/Step';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { useVerticalSelection } from './controller';
import { StepTitle } from '../../../components/StepTitle';
import { StepId } from '../../../enums/schema';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../../constants/general';

export const VerticalSelection = () => {
  const { onContinue, onVerticalChange, vertical } = useVerticalSelection();
  const isMobile = useMediaQuery(MOBILE_QUERY);

  return (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle stepId={StepId.VerticalSelection} />
        </Step.Content>

        <Step.Form>
          <SelectOption
            onEntitySelect={onVerticalChange}
            options={[
              {
                key: '1',
                entity: 'mowing',
                label: lsI18NService.t('verticalSelection.getAQuote', { vertical: 'mowing' }),
              },
              // Uncomment the following to add more verticals, see more on "controller.ts"
              // {
              //   key: '2',
              //   entity: 'treatment',
              //   label: lsI18NService.t('verticalSelection.getAQuote', { vertical: 'lawn treatment' }),
              // },
              // {
              //   key: '3',
              //   entity: 'other',
              //   label: lsI18NService.t('verticalSelection.otherService'),
              // },
            ]}
            textAlignment={isMobile ? 'center' : 'left'}
          />

          <Step.Footer>
            <Step.Action>
              <Button onPress={onContinue} mode="contained" trackID="vertical-selection-cta" disabled={!vertical}>
                {lsI18NService.t('continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
