import tw from 'tailwind-styled-components';

export const StyledIcon = tw.span`
  ml-1 cursor-pointer
`;

export const iconStyle = {
  textAlign: 'center',
  marginLeft: 'var(--s2)',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};
