import { Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';

import { VITE_BRAND } from '../../config/env';
import { MOBILE_QUERY } from '../../constants/general';
import { METADATA } from '../../constants/metadata';
import { StepId, Vertical } from '../../enums/schema';
import { lsI18NService } from '../../service';
import { StyledWrapper, styleSubTitle as styleSubTitleCommon, styleTitle as styleTitleCommon } from './styles';

interface StepTitleProps {
  stepId: StepId;
  vertical?: Vertical;
  titleSlot?: () => JSX.Element;
  centerOnMobile?: boolean;
}

export const StepTitle = ({
  stepId,
  vertical,
  titleSlot: TitleSlot = () => <></>,
  centerOnMobile = false,
}: StepTitleProps) => {
  const stepIdKey = _.camelCase(stepId);
  const isMobile = useMediaQuery(MOBILE_QUERY);
  const onMobile = centerOnMobile && isMobile ? { textAlign: 'center' } : {};
  const { displayName } = METADATA[VITE_BRAND as Brand];

  return (
    <StyledWrapper>
      <Text variant="headlineMedium" style={{ ...styleTitleCommon, ...onMobile }}>
        {lsI18NService.t(`${stepIdKey}.title`, { vertical, brand: displayName })} <TitleSlot />
      </Text>
      <Text variant="titleMedium" data-testid="step-desc" style={{ ...styleSubTitleCommon, ...onMobile }}>
        {lsI18NService.t(`${stepIdKey}.description`, { vertical })}
      </Text>
    </StyledWrapper>
  );
};
