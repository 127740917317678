import { gql } from 'graphql-request';
import { lsApi } from '../../../service/lsApi/lsApi';
import { MutationCreateContactArgs } from '../../../types/graphql';

export type CreateContactMutationVariables = MutationCreateContactArgs;
export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact?: { __typename?: 'Contact'; id: string } | null;
};

export const CreateContactDocument = gql`
  mutation CreateContact($prospectId: HashID!, $email: String!, $name: String!, $password: String!, $phone: String!) {
    createContact(prospectId: $prospectId, email: $email, name: $name, password: $password, phone: $phone) {
      id
    }
  }
`;

const contactApi = lsApi.injectEndpoints({
  endpoints: (build) => ({
    createContact: build.mutation<CreateContactMutation, CreateContactMutationVariables>({
      query: (variables) => {
        return { document: CreateContactDocument, variables };
      },
    }),
  }),
});

export const { useCreateContactMutation } = contactApi;
