import { useForm } from 'react-hook-form';

import { useExtraServices } from './useExtraServices';
import { useLawnTreatment } from './useLawnTreatment';
import { useCallback, useMemo } from 'react';
import { CartItem, addCartItem } from '../../../store/modules/cart/slice';
import { useDispatch } from 'react-redux';
import { ExtraServicesStep } from '../../../types/schema';

interface Props {
  currentStep: ExtraServicesStep;
}

export const useExtraServicesController = ({ currentStep }: Props) => {
  const dispatch = useDispatch();
  const { defaultValues: defaultExtra, extraServices } = useExtraServices({ currentStep });
  const { defaultValues: defaultLtr, lawnTreatment } = useLawnTreatment();
  const {
    control: ltrControl,
    getValues: getLtr,
    watch: watchLtr,
  } = useForm({ defaultValues: defaultLtr, mode: 'onChange' });
  const {
    control: extraServicesControl,
    getValues: getExtraServices,
    watch: watchExtraServices,
  } = useForm({ defaultValues: defaultExtra, mode: 'onChange' });

  const watchAllFields = watchExtraServices() && watchLtr();

  const selectedValues = useMemo(() => {
    const fields = { ...getExtraServices(), ...getLtr() };
    const fieldKeys = Object.keys(fields);
    const selectedOnes = fieldKeys.filter((key) => fields[key]);
    return selectedOnes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExtraServices, getLtr, watchAllFields]);

  const onQuoteServices = useCallback(
    (callback: () => void) => {
      const items = [...extraServices, ...lawnTreatment];
      const selectedItems = selectedValues.map((selected) =>
        items.find((item) => item.type === selected),
      ) as CartItem[];
      dispatch(addCartItem(selectedItems));

      callback();
    },
    [extraServices, lawnTreatment, selectedValues, dispatch],
  );

  return {
    selectedValues,
    lawnTreatment: {
      control: ltrControl,
      data: lawnTreatment,
    },
    extraServices: {
      control: extraServicesControl,
      data: extraServices,
    },
    onQuoteServices,
  };
};
