import { Step } from '../../../layout/Step';
import { StepId, StepType } from '../../../enums/schema';
import { Button, Text, Toast } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../service';
import { StepTitle } from '../../../components/StepTitle';
import { CartHeader } from '../../../components/CartHeader';
import { CompactFaq } from './components/CompactFaq';
import { Faq } from './components/Faq';
import { StyledFaqWrapper } from './styles';
import { Divider } from 'react-native-paper';
import { StyledCard, CardSection, CardSectionLine, StyledLink, PromoCodeSection, StyledIcon } from './styles';
import { centsToDollar } from '../../../helpers/currency';
import { useReviewAndConfirmation } from './controller';
import { ConfirmingScreen } from './components/ConfirmingScreen';
import { TRUST_AND_SAFETY_FEE } from '../../../constants/general';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateApp } from '../../../store/modules/app/slice';
import { AddressButton } from '../../../components/AddressButton';
import { resetContact } from '../../../store/modules/contact/slice';
import { resetProperty } from '../../../store/modules/property/slice';
import { resetProspect } from '../../../store/modules/prospect/slice';
import { resetCart } from '../../../store/modules/cart/slice';
import { To, useNavigate } from 'react-router-dom';
import { usePromoCode } from './usePromoCode';
import { useStepNavigation } from '../../../hooks/useStepNavigation';

const stepId = StepId.ReviewAndConfirmation;

export const ReviewAndConfirmation = () => {
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal, promocode } = usePromoCode();
  const { goToNextStep } = useStepNavigation({ stepId });

  const {
    handleSubmit,
    prospect,
    property,
    isReadyToBook,
    mutation: { isSuccess, isUninitialized },
  } = useReviewAndConfirmation();

  useEffect(() => {
    isSuccess && navigate(goToNextStep as To);
  }, [isSuccess, goToNextStep, navigate]);

  useEffect(() => {
    dispatch(updateApp({ headerRightSlot: 'address' }));
    return () => {
      dispatch(updateApp({ headerRightSlot: null }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickChangeAddress = useCallback(() => {
    dispatch(resetProspect());
    dispatch(resetContact());
    dispatch(resetProperty());
    dispatch(resetCart());

    navigate({ pathname: `/${StepType.Cart}/${StepId.ContactInfo}` });
  }, [dispatch, navigate]);

  return !isUninitialized ? (
    <ConfirmingScreen />
  ) : (
    <Step>
      <Step.Header>
        <CartHeader right={() => <AddressButton className="md:hidden" />} />
      </Step.Header>

      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} />
          <StyledFaqWrapper>
            <Faq />
          </StyledFaqWrapper>
        </Step.Content>

        <Step.Form>
          <StyledCard>
            <CardSection>
              <CardSectionLine>
                <Text variant="titleMedium">{lsI18NService.t('reviewAndConfirmation.addressTitle')}</Text>
              </CardSectionLine>
              <CardSectionLine>
                <Text variant="bodyMedium">{lsI18NService.t('address', property)}</Text>
                <StyledLink role="button" onClick={onClickChangeAddress}>
                  {lsI18NService.t('reviewAndConfirmation.changeAddress')}
                </StyledLink>
              </CardSectionLine>
              <Divider />
            </CardSection>

            <CardSection>
              <Text variant="titleMedium">{lsI18NService.t('reviewAndConfirmation.serviceTitle')}</Text>
              {prospect.selectedServiceEstimates?.map((service) => (
                <CardSectionLine key={service.id}>
                  <Text variant="bodyMedium">{lsI18NService.t(`service.${service.type}`)}</Text>
                  <Text variant="bodyMedium">
                    {lsI18NService.t('scheduling.priceTitle', {
                      price: centsToDollar(service.selectedEstimate?.amount),
                      frequency: lsI18NService.t(`cycle.${service.selectedEstimate?.cycle}`),
                    })}
                  </Text>
                </CardSectionLine>
              ))}
              <CardSectionLine>
                <StyledIcon>
                  <Text variant="bodyMedium">{lsI18NService.t('reviewAndConfirmation.serviceTrust')}</Text>
                  <CompactFaq />
                </StyledIcon>
                <Text variant="bodyMedium">${centsToDollar(TRUST_AND_SAFETY_FEE, 2)}</Text>
              </CardSectionLine>
              <Divider />
            </CardSection>

            <CardSection>
              <Text variant="titleMedium">{lsI18NService.t('reviewAndConfirmation.paymentTitle')}</Text>
              <Text variant="bodyMedium">{lsI18NService.t('reviewAndConfirmation.paymentCard', prospect)}</Text>
              <Divider />
            </CardSection>

            <CardSection>
              <CardSectionLine>
                <Text variant="titleMedium">{lsI18NService.t('reviewAndConfirmation.totalTitle')}</Text>
                <Text variant="titleMedium">${centsToDollar(0, 2)}</Text>
              </CardSectionLine>
              <Text variant="bodyMedium">{lsI18NService.t('reviewAndConfirmation.totalText')}</Text>
            </CardSection>
          </StyledCard>

          <Step.Footer>
            <PromoCodeSection>
              <Text variant="bodySmall">
                {promocode?.description
                  ? `${lsI18NService.t('reviewAndConfirmation.codeAdded')}: ${promocode?.description}`
                  : lsI18NService.t('reviewAndConfirmation.codeTitle')}
              </Text>
              <StyledLink onClick={() => openModal({ setShowToast })}>
                {promocode
                  ? lsI18NService.t('reviewAndConfirmation.codeChange')
                  : lsI18NService.t('reviewAndConfirmation.codeAdd')}
              </StyledLink>
            </PromoCodeSection>
            <Step.Action>
              <Button
                onPress={handleSubmit}
                mode="contained"
                testID="book-btn"
                trackID={`${stepId}-continue`}
                disabled={!isReadyToBook || !isUninitialized}
                loading={!isUninitialized}
              >
                {lsI18NService.t('reviewAndConfirmation.continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
      {showToast && (
        <Toast
          icon="check-circle-outline"
          variant="success"
          description={lsI18NService.t('reviewAndConfirmation.promoCode.codeApplied')}
          msToHide={2000}
        />
      )}
    </Step>
  );
};
