import { To, useNavigate } from 'react-router-dom';
import { List } from 'react-native-paper';
import { Step } from '../../../layout/Step';
import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { StepTitle } from '../../../components/StepTitle';
import { CartHeader } from '../../../components/CartHeader';
import { CheckoutForm } from './components/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { VITE_STRIPE_API_KEY } from '../../../config/env';
import { Faq } from '../../../components/Faq';
import { StyledFaqWrapper } from '../../../components/Faq/styles';
import { CompactFaq } from './components/CompactFaq';
import { lsI18NService } from '../../../service';
import { Accordion, Text } from '@lawnstarter/ls-react-common/atoms';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { useCallback } from 'react';
import { trackHelpArticleExpanded } from '../../../service/segment/trackers';
import _ from 'lodash';
import { Events } from '../../../enums/events';

const stepId = StepId.Payment;

const stripePromise = loadStripe(String(VITE_STRIPE_API_KEY));

export const Payment = () => {
  const { vertical, goToNextStep } = useStepNavigation({ stepId });
  const navigate = useNavigate();
  const { payloadBuilder } = useTrackPayload(Events.HelpArticleExpanded);
  const onOpen = useCallback(
    (articleTitle: string) => {
      const payload = payloadBuilder({ articleTitle, helpContext: 'payment' });
      payload && trackHelpArticleExpanded(payload);
    },
    [payloadBuilder],
  );

  return (
    <Step>
      <Step.Header>
        <CartHeader right={() => <></>} />
      </Step.Header>

      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} vertical={vertical} titleSlot={() => <CompactFaq />} />
          <StyledFaqWrapper>
            <Faq>
              <>
                <Accordion
                  title={lsI18NService.t('payment.faq.question1.title')}
                  trackID="how-payment"
                  testID="faq-how-payment"
                  onOpen={() => onOpen(lsI18NService.t('payment.faq.question1.title'))}
                >
                  <ul>
                    <li className="pl-2">
                      <List.Item
                        left={() => <Text>&#x25CF;</Text>}
                        titleNumberOfLines={0}
                        title={lsI18NService.t('payment.faq.question1.content')}
                      />
                    </li>
                  </ul>
                </Accordion>
              </>
            </Faq>
          </StyledFaqWrapper>
        </Step.Content>

        <Step.Form>
          <Elements stripe={stripePromise}>
            <CheckoutForm onSuccess={() => navigate(goToNextStep as To)} />
          </Elements>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
