import { gql } from 'graphql-request';
import { lsApi } from '../../../service/lsApi/lsApi';
import { FormValues } from '../../../pages/Cart/TermsOfService/types';
import { MutationAcceptTermsArgs } from '../../../types/graphql';

export type AcceptTermsMutationVariables = Omit<MutationAcceptTermsArgs, 'termsId'> & {
  terms: FormValues;
};

export type AcceptTermsMutation = {
  __typename?: 'Mutation';
  acceptTerms?: { __typename?: 'ProspectTermsAcceptance' } | null;
};

const getTermId = (term: string) => {
  const termMap = {
    cancellationPeriod: '48_hours',
    longGrassFee: 'long_grass',
    cutMinimum: '3_cut',
  };

  return termMap[term as keyof typeof termMap];
};

const generateMutationDocument = (terms: FormValues) => {
  let mutationString = 'mutation AcceptTerms($prospectServiceId: HashID!, $version: String!) {';

  Object.entries(terms).forEach(([termValue]) => {
    mutationString += `
      ${termValue}: acceptTerms(prospectServiceId: $prospectServiceId, version: $version, termsId: "${getTermId(termValue)}") {
        id
      }`;
  });

  mutationString += '}';

  return mutationString;
};

export const AcceptTermsDocument = (terms: FormValues) => gql`
  ${generateMutationDocument(terms)}
`;

const prospectApi = lsApi.injectEndpoints({
  endpoints: (build) => ({
    acceptTerms: build.mutation<AcceptTermsMutation, AcceptTermsMutationVariables>({
      query: (variables) => ({ document: AcceptTermsDocument(variables.terms), variables }),
    }),
  }),
});

export const { useAcceptTermsMutation } = prospectApi;
