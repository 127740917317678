import { Step } from '../../../layout/Step';
import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../service';
import { StepTitle } from '../../../components/StepTitle';
import { CartHeader } from '../../../components/CartHeader';
import { StyledContainer, StyledDescription, StyledLink } from './styles';
import { useTermsOfService } from './controller';
import { To } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Form } from './Form';
import { useEffect } from 'react';
import { VITE_BRAND } from '../../../config/env';

const stepId = StepId.TermsOfService;

export const TermsOfService = () => {
  const navigate = useNavigate();
  const { goToNextStep, vertical } = useStepNavigation({ stepId });
  const { form, handleSubmit, mutation } = useTermsOfService();

  useEffect(() => {
    mutation.isSuccess && navigate(goToNextStep as To);
  }, [mutation.isSuccess, goToNextStep, navigate]);

  return (
    <Step>
      <Step.Header>
        <CartHeader />
      </Step.Header>

      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} vertical={vertical} />
        </Step.Content>

        <Step.Form>
          <StyledContainer>
            <Form control={form.control} />
          </StyledContainer>

          <Step.Footer>
            <StyledDescription>
              <Text variant="bodyMedium">
                {`${lsI18NService.t('termsOfService.description')} `}
                <StyledLink
                  href={
                    VITE_BRAND === 'lawnstarter'
                      ? 'https://www.lawnstarter.com/terms-of-service'
                      : 'https://lawnlove.com/terms'
                  }
                  target="_blank"
                >
                  {lsI18NService.t('termsOfService.title')}
                </StyledLink>
              </Text>
            </StyledDescription>

            <Step.Action>
              <Button
                onPress={handleSubmit}
                mode="contained"
                trackID={`${stepId}-accept`}
                disabled={!form.isValid || mutation.isLoading || mutation.isSuccess}
                loading={mutation.isLoading || mutation.isSuccess}
              >
                {lsI18NService.t('accept')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
