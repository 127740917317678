import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../..';
export interface CartItem {
  type: string;
  estimateId: string;
  amount?: number;
}

export interface CartState {
  items: CartItem[];
}

export const initialState: CartState = {
  items: [],
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addCartItem(state, { payload }: PayloadAction<CartItem[]>) {
      state.items.push(...payload);
    },
    removeCartItem(state, { payload }: PayloadAction<{ estimateId: string }>) {
      state.items = state.items.filter(({ estimateId }) => estimateId !== payload.estimateId);
    },
    resetCart: () => initialState,
  },
});

export const { addCartItem, removeCartItem, resetCart } = slice.actions;

export default slice.reducer;

export const selectServices = (state: RootState) => state.cart as CartState;
