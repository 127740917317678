import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Events } from '../enums/events';
import { StepId } from '../enums/schema';
import { selectProspect } from '../store/modules/prospect/slice';
import { selectProperty } from '../store/modules/property/slice';
import { useStepNavigation } from './useStepNavigation';
import { PayloadBuilder, TrackCommonArgs, TrackHelpArticleExpandedInput, TrackHelpViewedInput } from '../types/track';

type PayloadBuilderType<T extends Events> = T extends keyof PayloadBuilder ? PayloadBuilder[T] : never;

export const useTrackPayload = <T extends Events>(event: T) => {
  const { stepId } = useParams();
  const { vertical } = useStepNavigation({ stepId: stepId as StepId });
  const { id: prospectId } = useSelector(selectProspect);
  const { name, address1: street, city, state, zip } = useSelector(selectProperty);

  const commonPayload: TrackCommonArgs = {
    customer_signup_page: stepId as string,
    name,
    prospect_hash_id: prospectId,
    primary_interest: vertical,
    address: {
      street,
      city,
      state,
      zip,
    },
  };

  let payloadBuilder: PayloadBuilderType<T> | (() => null) = () => null;

  switch (event) {
    case Events.HelpViewed:
      payloadBuilder = (({ helpContext }: TrackHelpViewedInput) => ({
        ...commonPayload,
        help_context: helpContext,
      })) as PayloadBuilderType<T>;
      break;
    case Events.HelpArticleExpanded:
      payloadBuilder = (({ articleTitle, helpContext }: TrackHelpArticleExpandedInput) => ({
        ...commonPayload,
        help_context: helpContext,
        selected_article: _.kebabCase(articleTitle),
      })) as PayloadBuilderType<T>;
      break;
    default:
      Sentry.captureMessage(`No payload builder for ${event}`);
  }

  return { payloadBuilder };
};
