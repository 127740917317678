import { StyledHeader, StyledWrapper } from './styles';
import { useDynamicSvgImport } from '../../hooks/useDynamicSvgImport';
import { useSelector } from 'react-redux';
import { selectApp } from '../../store/modules/app/slice';
import { AddressButton } from '../../components/AddressButton';

export default function Header() {
  const { Svg: Logo } = useDynamicSvgImport('logo');
  const { shouldShowHeader, headerRightSlot } = useSelector(selectApp);

  const RightSlot = () => {
    switch (headerRightSlot) {
      case 'address':
        return <AddressButton className="hidden md:flex" />;
      default:
        return <></>;
    }
  };

  return (
    shouldShowHeader && (
      <StyledWrapper>
        <StyledHeader>
          <Logo />
          <RightSlot />
        </StyledHeader>
      </StyledWrapper>
    )
  );
}
