import { useEffect } from 'react';
import { lsSegmentService } from '../..';
import { useLocation } from 'react-router-dom';

export function useSegmentPage() {
  const location = useLocation();

  useEffect(() => {
    const payload = {
      referrer: document.referrer,
      ...location,
    };
    lsSegmentService.page(payload);
  }, [location]);
}
