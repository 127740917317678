import { useForm } from 'react-hook-form';
import { useTerms } from './useTerms';
import { useCallback } from 'react';
import { FormValues } from './types';
import { useSelector } from 'react-redux';
import { selectProspect } from '../../../store/modules/prospect/slice';
import { ProspectService } from '../../../types/graphql';
import { TOS_VERSION } from '../../../constants/general';

const defaultValues = {
  cancellationPeriod: false,
  longGrassFee: false,
  cutMinimum: false,
};

export const useTermsOfService = () => {
  const { control, formState, getValues } = useForm<FormValues>({ defaultValues });
  const { handleTerms, data, isSuccess, isLoading } = useTerms();
  const { services } = useSelector(selectProspect);

  const handleSubmit = useCallback(() => {
    services?.forEach((service: ProspectService) => {
      handleTerms({
        prospectServiceId: service.id,
        terms: getValues(),
        version: TOS_VERSION,
      });
    });
  }, [handleTerms, getValues, services]);

  return {
    handleSubmit,
    form: {
      control,
      isValid: formState.isValid,
    },
    mutation: {
      data,
      isLoading,
      isSuccess,
    },
  };
};
