import { AppProvider, ModalProvider } from '@lawnstarter/ls-react-common/contexts';
import { ModalViewer } from '@lawnstarter/ls-react-common/organisms';
import tw from 'tailwind-styled-components';

import { VITE_BRAND } from './config/env';
import { useDocumentTitle } from './hooks/useDocumentTitle';
import { useSegmentIdentify } from './service/segment/hooks/useSegmentIdentify';
import { useSegmentPage } from './service/segment/hooks/useSegmentPage';
import { useIntent } from './hooks/useIntent';
import CSSBindTheme from './layout/CSSBindTheme';
import Header from './layout/Header';
import Main from './layout/Main';

import '../index.css';
import { useAppVersion } from './hooks/useAppVersion';
import { useErrorHandler } from './hooks/useErrorHandler';

export const StyledWrapper = tw.section`
  w-full max-w-[1536px] mx-auto p-4 flex flex-1 flex-col
`;

export default function App() {
  useDocumentTitle();
  useIntent();
  useSegmentIdentify();
  useSegmentPage();
  useAppVersion();
  const { component: ErrorHandler } = useErrorHandler();

  return (
    <AppProvider brand={VITE_BRAND}>
      <ModalProvider>
        <CSSBindTheme />
        <ErrorHandler />
        <StyledWrapper>
          <Header />
          <Main />
        </StyledWrapper>
        <ModalViewer />
      </ModalProvider>
    </AppProvider>
  );
}
