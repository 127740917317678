import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { selectProperty } from '../../../store/modules/property/slice';
import { ProspectServiceEstimate } from '../../../types/graphql';
import { StepId, StepType } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { useAcceptEstimateMutation, useLazyGetProspectQuery } from '../../../store/modules/prospect/api';
import { selectEstimatesByVertical, selectProspect } from '../../../store/modules/prospect/slice';
import { LOADING_DELAY_IN_MS } from '../../../constants/general';
import { lsI18NService } from '../../../service';

const stepId = StepId.Scheduling;

export const useScheduling = () => {
  const navigate = useNavigate();
  const { goToNextStep, vertical, hashId, currentStep } = useStepNavigation({ stepId });
  const shouldDisplayStartDate = Boolean(_.get(currentStep, 'options.shouldDisplayStartDate', true));

  const property = useSelector(selectProperty);
  const estimates = useSelector((state) => selectEstimatesByVertical(state, vertical));
  const prospect = useSelector(selectProspect);
  const [getProspect, getProspectMutation] = useLazyGetProspectQuery();

  const [estimate, setEstimate] = useState<Partial<ProspectServiceEstimate>>({});
  const [startDate, setStartDate] = useState(dayjs(new Date()).add(2, 'days'));

  const [acceptEstimate, acceptEstimateMutation] = useAcceptEstimateMutation();

  const selectOptions = useMemo(
    () =>
      estimates.map((estimate) => ({
        key: estimate.id,
        entity: estimate,
        label: `${lsI18NService.t(`cycle.${estimate.cycle}`)} ${lsI18NService.t(`service.${vertical}`)}`,
      })),
    [estimates, vertical],
  );

  const hasEstimates = useMemo(() => estimates.length > 0, [estimates.length]);

  const isLoaded = useMemo(
    () => prospect.id === hashId || (!getProspectMutation.isUninitialized && !getProspectMutation.isLoading),
    [getProspectMutation.isLoading, getProspectMutation.isUninitialized, hashId, prospect.id],
  );

  const hasError = useMemo(
    () => isLoaded && (getProspectMutation.isError || !hasEstimates),
    [getProspectMutation.isError, hasEstimates, isLoaded],
  );

  useEffect(() => {
    if (!isLoaded) {
      getProspect({ id: hashId });
    }
  }, [getProspect, hashId, isLoaded]);

  useEffect(() => {
    if (!estimate.amount && hasEstimates) {
      setEstimate(estimates[0]);
    }
  }, [estimate.amount, estimates, hasEstimates]);

  useEffect(() => {
    hasError &&
      setTimeout(() => navigate(`/${StepType.Cart}/${StepId.ContactInfo}?intent=${vertical}`), LOADING_DELAY_IN_MS);
  }, [hasError, vertical, navigate]);

  useEffect(() => {
    acceptEstimateMutation.isSuccess && goToNextStep && navigate(goToNextStep);
  }, [acceptEstimateMutation.isSuccess, goToNextStep, navigate]);

  const handleSubmit = useCallback(() => {
    acceptEstimate({ estimateId: estimate.id, startDate: startDate.format('YYYY-MM-DD HH:mm:ss') });
  }, [acceptEstimate, estimate.id, startDate]);

  return {
    vertical,
    property,
    selectOptions,
    hasEstimates,
    isLoaded,
    hasError,
    goToNextStep,
    shouldDisplayStartDate,
    form: {
      handleSubmit,
      isValid: Boolean(estimate.amount && startDate),
      estimate,
      setEstimate,
      startDate,
      setStartDate,
      mutation: acceptEstimateMutation,
    },
  };
};
