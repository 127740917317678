export enum Vertical {
  Mowing = 'mowing',
  Treatment = 'treatment',
}

export enum StepType {
  Cart = 'cart',
  Onboarding = 'onboarding',
}

export enum StepId {
  // Cart
  VerticalSelection = 'vertical-selection',
  ContactInfo = 'contact-info',
  Scheduling = 'scheduling',
  AccountAndPayment = 'account-and-payment',
  TermsOfService = 'tos',
  Payment = 'payment',
  ReviewAndConfirmation = 'review-and-confirmation',
  ExtraServices = 'extra-services',
  Welcome = 'welcome',
  // Temporary
  LawnTreatment = 'lawn-treatment',
  // Onboarding - NOT USED YET
  MultiFamily = 'multi-family',
  UnitNumber = 'unit-number',
  Gate = 'gate',
}
