import { Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { Faq as FaqCommon, Question } from '@lawnstarter/ls-react-common/molecules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { List } from 'react-native-paper';

import { lsI18NService } from '../../../../../service';
import { VITE_BRAND } from '../../../../../config/env';
import { METADATA } from '../../../../../constants/metadata';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';
import { Events } from '../../../../../enums/events';
import { useCallback } from 'react';
import { trackHelpArticleExpanded } from '../../../../../service/segment/trackers';

export const Faq = () => {
  const { colors, spacing } = useAppTheme();
  const { displayName } = METADATA[VITE_BRAND as Brand];
  const { payloadBuilder } = useTrackPayload(Events.HelpArticleExpanded);
  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>, articleTitle: string) => {
      const target = e.target as HTMLElement;
      const isExpanding = !(target?.closest('[aria-expanded]')?.getAttribute('aria-expanded') === 'true');

      if (!isExpanding) {
        return;
      }

      const payload = payloadBuilder({ articleTitle, helpContext: 'trust_safety' });
      payload && trackHelpArticleExpanded(payload);
    },
    [payloadBuilder],
  );

  return (
    <FaqCommon
      header={lsI18NService.t('faq')}
      style={{
        wrapper: {
          borderColor: colors.gray.secondary,
          borderRadius: spacing.s2,
          borderStyle: 'solid',
          borderWidth: 1,
          flex: 1,
          paddingHorizontal: spacing.s5,
          paddingVertical: spacing.s3,
        },
        wrapperScroll: {
          height: 300,
        },
      }}
    >
      <Question
        onPress={(e) => onOpen(e, lsI18NService.t(`reviewAndConfirmation.faq.question1.title`))}
        question={lsI18NService.t(`reviewAndConfirmation.faq.question1.title`)}
        trackID={`faq-review-confirm`}
      >
        <ul>
          <li className="pl-2">
            <List.Item
              left={() => <Text>&#x25CF;</Text>}
              titleNumberOfLines={0}
              title={lsI18NService.t(`reviewAndConfirmation.faq.question1.content`, { brand: displayName })}
            />
          </li>
        </ul>
      </Question>
    </FaqCommon>
  );
};
