import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Text, Toast } from '@lawnstarter/ls-react-common/atoms';

import { useDynamicSvgImport } from '../../hooks/useDynamicSvgImport';
import { updateApp } from '../../store/modules/app/slice';

import { StyledBody, StyledContent, StyledToast, spinnerStyle, titleStyle } from './styles';

type DinamicImport = { name: string; isBranded: boolean };
type ToastElement = { description: string; msToHide: number | null };

export type LoadingScreenProps = {
  image: DinamicImport;
  title?: string;
  children: ReactNode;
  toast?: ToastElement;
  hideSpinner?: boolean;
};

export const LoadingScreen = (props: LoadingScreenProps) => {
  const { Svg } = useDynamicSvgImport(props.image.name, props.image.isBranded);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateApp({ shouldShowHeader: false }));
    return () => {
      dispatch(updateApp({ shouldShowHeader: true }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBody data-testid="loading-screen">
      <Svg />
      {props.title && (
        <StyledContent>
          <Text variant="headlineSmall" style={titleStyle}>
            {props.title}
          </Text>
        </StyledContent>
      )}

      <StyledContent className="max-w-md">
        {props.children}
        {!props.hideSpinner && <Spinner style={spinnerStyle} size={32} />}
      </StyledContent>

      {props.toast && (
        <StyledToast>
          <Toast description={props.toast.description} msToHide={props.toast.msToHide} />
        </StyledToast>
      )}
    </StyledBody>
  );
};
