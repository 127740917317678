/* eslint-disable no-console */
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { useStoreProspectPaymentTokenMutation } from '../../../../../store/modules/prospect/api';
import { result } from 'lodash';
import { useParams } from 'react-router-dom';

interface useCheckoutFormArgs {
  name: string;
  zip: string;
}

export const useCheckoutForm = ({ name, zip: address_zip }: useCheckoutFormArgs) => {
  const stripe = useStripe();
  const elements = useElements();
  const { hashId } = useParams();

  const [storeProspectPaymentToken, { isSuccess, isLoading }] = useStoreProspectPaymentTokenMutation();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      try {
        const card = elements?.getElement(CardNumberElement) as StripeCardNumberElement;
        const result = await stripe?.createToken(card, { address_country: 'US', address_zip, name });

        if (result?.token) {
          storeProspectPaymentToken({ prospectId: hashId, paymentToken: result.token.id });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [address_zip, elements, hashId, name, storeProspectPaymentToken, stripe],
  );

  return { handleSubmit, mutation: { isSuccess, isLoading }, stripe: result, name };
};
