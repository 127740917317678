import { Step } from '../../../layout/Step';
import { StepId, StepType } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { Button, TextInput, Text } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../service';
import { useNavigate } from 'react-router';
import { CartHeader } from '../../../components/CartHeader';
import {
  StyledFullNameWrapper,
  StyledTextInputWrapper,
  styleDesktopToast,
  styleFullnamePlaceholder,
  styleMobileToast,
} from './styles';
import { useEffect } from 'react';
import { StepTitle } from '../../../components/StepTitle';
import { updateContact } from '../../../store/modules/contact/slice';
import { store } from '../../../store';
import { To } from 'react-router-dom';
import { useAccountAndPayment } from './controller';
import { BackButton } from '../../../components/BackButton';
import { VITE_MY_LOGIN_URL } from '../../../config/env';
import { ErrorToast } from '../../../components/ErrorToast';

const stepId = StepId.AccountAndPayment;

export const AccountAndPayment = () => {
  const navigate = useNavigate();
  const { goToNextStep, vertical, hashId } = useStepNavigation({ stepId });
  const { handleSubmit, form, mutation } = useAccountAndPayment();
  const emailTakenAction = {
    label: lsI18NService.t('login'),
    onPress: () => window.location.assign(VITE_MY_LOGIN_URL),
  };

  useEffect(() => {
    const id = mutation.data?.createContact?.id;
    store.dispatch(updateContact({ id, email: form.email }));
    navigate(goToNextStep as To);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  return (
    <Step>
      <Step.Header>
        <CartHeader left={() => <BackButton pathname={`/${StepType.Cart}/${hashId}/${StepId.Scheduling}`} />} />
      </Step.Header>

      {mutation.errors.isEmailAlreadyTaken && (
        <ErrorToast
          className={styleMobileToast}
          description={lsI18NService.t('formErrors.emailAlreadyInUse')}
          action={emailTakenAction}
        />
      )}

      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} vertical={vertical} />
        </Step.Content>

        <Step.Form>
          {mutation.errors.isEmailAlreadyTaken && (
            <ErrorToast
              className={styleDesktopToast}
              description={lsI18NService.t('formErrors.emailAlreadyInUse')}
              action={emailTakenAction}
            />
          )}

          <StyledTextInputWrapper>
            <Text style={styleFullnamePlaceholder}>{lsI18NService.t('accountAndPayment.nameInput.fullName')}*</Text>
            <StyledFullNameWrapper>
              <TextInput
                placeholder={lsI18NService.t('accountAndPayment.nameInput.firstName')}
                name="firstName"
                testID="text-input-first-name"
                rules={{ required: true }}
                control={form.control}
                containerStyle={{ flex: 1 }}
              />
              <TextInput
                placeholder={lsI18NService.t('accountAndPayment.nameInput.lastName')}
                name="lastName"
                testID="text-input-last-name"
                rules={{ required: true }}
                control={form.control}
                containerStyle={{ flex: 1 }}
              />
            </StyledFullNameWrapper>
          </StyledTextInputWrapper>

          <StyledTextInputWrapper>
            <TextInput.Email
              label={`${lsI18NService.t('accountAndPayment.emailInput.label')}*`}
              name="email"
              testID="text-input-email"
              placeholder={lsI18NService.t('accountAndPayment.emailInput.label')}
              control={form.control}
              rules={{
                required: lsI18NService.t('accountAndPayment.emailInput.requiredMessage'),
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: lsI18NService.t('accountAndPayment.emailInput.patternMessage'),
                },
              }}
            />
          </StyledTextInputWrapper>
          <StyledTextInputWrapper>
            <TextInput
              label={`${lsI18NService.t('accountAndPayment.passwordInput.label')}*`}
              name="password"
              testID="text-input-password"
              secureTextEntry={true}
              placeholder={lsI18NService.t('accountAndPayment.passwordInput.label')}
              control={form.control}
              rules={{
                required: lsI18NService.t('accountAndPayment.passwordInput.requiredMessage'),
                minLength: { value: 6, message: lsI18NService.t('accountAndPayment.passwordInput.lenghtMessage') },
              }}
            />
            {!form.formState.dirtyFields['password'] && (
              <Text variant="bodySmall" data-testid="step-desc">
                {lsI18NService.t('accountAndPayment.passwordInput.lenghtMessage')}
              </Text>
            )}
          </StyledTextInputWrapper>

          <Step.Footer>
            <Step.Action>
              <Button
                onPress={handleSubmit}
                mode="contained"
                trackID={`${stepId}-continue`}
                disabled={!form.formState.isValid || mutation.isLoading || mutation.isSuccess}
                loading={mutation.isLoading || mutation.isSuccess}
              >
                {lsI18NService.t('continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
