import { DetailedBadge as DetailedBadgeCommon } from '@lawnstarter/ls-react-common/molecules';
import { lsI18NService } from '../../service';
import { StyledDetailedBadge, styleDetailedBadge } from './styles';
import { PhoneDetailedBadgeTypes } from './types';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { VITE_BRAND } from '../../config/env';
import { METADATA } from '../../constants/metadata';

export const PhoneDetailedBadge = ({ trackID, testID, className }: PhoneDetailedBadgeTypes) => {
  const { phone } = METADATA[VITE_BRAND as Brand];

  return (
    <StyledDetailedBadge className={className}>
      <DetailedBadgeCommon
        label={phone.formatted}
        header={lsI18NService.t('contactInfo.callToSignUp')}
        trackID={trackID}
        testID={testID}
        icon="phone"
        style={styleDetailedBadge}
        onPress={() => window.location.assign(`tel:${phone.raw}`)}
      />
    </StyledDetailedBadge>
  );
};
