import { Icon } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { StyledActionBar, StyledSlot, StyledWrapperPhone } from './styles';
import { METADATA } from '../../constants/metadata';
import { VITE_BRAND } from '../../config/env';
import { BackButton } from '../BackButton';

interface CartHeaderProps {
  left?: () => JSX.Element;
  right?: () => JSX.Element;
}

export const CartHeader = (props: CartHeaderProps) => {
  const { left, right } = props;
  const { colors } = useAppTheme();
  const { phone } = METADATA[VITE_BRAND as Brand];

  return (
    <StyledActionBar>
      <StyledSlot>{left ? left() : <BackButton />}</StyledSlot>

      <StyledSlot>
        {right ? (
          right()
        ) : (
          <StyledWrapperPhone onClick={() => window.location.assign(`tel:${phone.raw}`)}>
            <Icon color={colors.primary} name="phone" size={26} testID="detailed-badge-phone" />
          </StyledWrapperPhone>
        )}
      </StyledSlot>
    </StyledActionBar>
  );
};
