import { setFontWeight, theme } from '@lawnstarter/ls-react-common';
import tw from 'tailwind-styled-components';

// FONT CHANGE: exception to match the title with Mockup provided
export const styleTitle = {
  flex: 1,
  flexWrap: 'wrap',
  marginBottom: theme.spacing.s2,
  textWrap: 'balance',
  ...setFontWeight('600'),
};

export const styleSubTitle = {
  color: theme.colors.outline,
  ...setFontWeight('400'),
};

export const StyledWrapper = tw.div`
  flex flex-col flex-1
`;
