import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Step } from '../../../layout/Step';
import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { StepTitle } from '../../../components/StepTitle';
import { useContactInfo } from './controller';
import { CalculationScreen } from './components/CalculationScreen';
import { CAPTCHA_ACTIVATED } from '../../../constants/general';
import { ContactInfoForm } from './components/Form';

const stepId = StepId.ContactInfo;

export const ContactInfo = () => {
  const navigate = useNavigate();
  const { currentStep, nextStep, vertical } = useStepNavigation({ stepId });
  const { onSubmit, onSetAddress, setAddressInput, form, mutation, delayIsOver } = useContactInfo();
  const [captchaVerification, setCaptchaVerification] = useState<boolean>(false);
  const [showCalculationScreen, setShowCalculationScreen] = useState(false);

  useEffect(() => {
    const prospectId = mutation.data?.createProspect?.prospect.id;

    const shouldNavigate = CAPTCHA_ACTIVATED
      ? prospectId && delayIsOver && captchaVerification
      : prospectId && delayIsOver;

    shouldNavigate && navigate(`/${currentStep.type}/${prospectId}/${nextStep?.id}`);
  }, [
    navigate,
    currentStep.type,
    nextStep?.id,
    mutation.data?.createProspect?.prospect.id,
    delayIsOver,
    captchaVerification,
  ]);

  const onPress = async () => {
    if (mutation.isError) {
      mutation.reset();
      setCaptchaVerification(false);
    }

    setShowCalculationScreen(true);
  };

  useEffect(() => {
    if (!captchaVerification) {
      return;
    }

    onSubmit();
  }, [captchaVerification, onSubmit]);

  const onCaptchaVerified = (_token: string | null) => setCaptchaVerification(true);

  return (showCalculationScreen || mutation.isAutofill) && !mutation.isError ? (
    <CalculationScreen onCaptchaVerified={onCaptchaVerified} />
  ) : (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} vertical={vertical} />
        </Step.Content>
        <ContactInfoForm
          control={form.control}
          onPress={onPress}
          onSetAddress={onSetAddress}
          stepId={stepId}
          isValid={form.isValid}
          setAddressInput={setAddressInput}
        />
      </Step.Body>
    </Step>
  );
};
