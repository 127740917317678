import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Vertical } from '../enums/schema';
import { isVertical } from '../helpers/vertical';
import { LocalStorage, QueryString } from '../enums/storage';

export function useIntent() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const intent = searchParams.get(QueryString.Intent) as string;
    const vertical = intent && isVertical(intent) ? intent : Vertical.Mowing;

    localStorage.setItem(LocalStorage.Intent, vertical);
  }, [searchParams]);
}
