import { Faq as FaqCommon } from '@lawnstarter/ls-react-common/molecules';
import { lsI18NService } from '../../../../../service';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Questions } from '../Questions';

export const Faq = () => {
  const { colors, spacing } = useAppTheme();

  return (
    <FaqCommon
      header={lsI18NService.t('faq')}
      style={{
        wrapper: {
          borderColor: colors.gray.secondary,
          borderRadius: spacing.s2,
          borderStyle: 'solid',
          borderWidth: 1,
          flex: 1,
          paddingHorizontal: spacing.s5,
          paddingVertical: spacing.s3,
        },
        wrapperScroll: {
          height: 300,
        },
      }}
    >
      <Questions />
    </FaqCommon>
  );
};
