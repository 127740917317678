import { useCallback } from 'react';

import { useAcceptTermsMutation } from '../../../store/modules/termsOfService/api';
import { HandleTermsInput } from './types';

export const useTerms = () => {
  const [acceptTerms, { data, isSuccess, isLoading }] = useAcceptTermsMutation();

  const handleTerms = useCallback(
    (input: HandleTermsInput) => {
      acceptTerms({
        prospectServiceId: input.prospectServiceId,
        version: input.version,
        terms: input.terms,
      });
    },
    [acceptTerms],
  );

  return { handleTerms, data, isSuccess, isLoading };
};
