import { Text } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../../../service';
import { LoadingScreen } from '../../../../LoadingScreen';
import { StyledContentWrapper } from './styles';

export const ConfirmingScreen = () => {
  return (
    <LoadingScreen
      image={{ name: 'gnomeLawnThinking', isBranded: false }}
      title={lsI18NService.t('reviewAndConfirmation.confirming.title')}
    >
      <Text variant="bodyLarge">{lsI18NService.t('reviewAndConfirmation.confirming.description')}</Text>
      <StyledContentWrapper>
        <Text variant="bodyLarge">{lsI18NService.t('reviewAndConfirmation.confirming.additionalDetails')}</Text>
      </StyledContentWrapper>
    </LoadingScreen>
  );
};
