import { Text } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../../../service';
import { LoadingScreen } from '../../../../LoadingScreen';
import { StyledLoadingContentWrapper } from './styles';
import { Turnstile } from '../../../../../components/Turnstile';
import { useEffect, useState } from 'react';
import { CAPTCHA_ACTIVATED } from '../../../../../constants/general';

interface Props {
  onCaptchaVerified?: (token: string | null) => void;
}

export const CalculationScreen = ({ onCaptchaVerified }: Props) => {
  const [hideSpinner, setHideSpinner] = useState(false);

  const onRender = () => {
    setHideSpinner(true);
  };

  const onVerify = (token: string | null) => {
    setHideSpinner(false);
    onCaptchaVerified && onCaptchaVerified(token);
  };

  // Automatically calls onVerify when captcha is not enabled
  useEffect(() => {
    !CAPTCHA_ACTIVATED && onVerify(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CAPTCHA_ACTIVATED]);

  return (
    <LoadingScreen
      data-testid="calc-title"
      image={{ name: 'gnomeLawnThinking', isBranded: false }}
      title={lsI18NService.t('calculationScreen.title')}
      toast={{ description: lsI18NService.t('calculationScreen.additionalDetails'), msToHide: null }}
      hideSpinner={hideSpinner}
    >
      <Text variant="bodyLarge">{lsI18NService.t('calculationScreen.description')}</Text>

      <StyledLoadingContentWrapper>
        <Text variant="bodyLarge">{lsI18NService.t('calculationScreen.additionalDetails')}</Text>
      </StyledLoadingContentWrapper>
      {CAPTCHA_ACTIVATED && <Turnstile onVerify={onVerify} onRender={onRender} hideOnVerified={true} />}
    </LoadingScreen>
  );
};
