import tw from 'tailwind-styled-components';

export const StyledLink = tw.a`
  underline text-primary
`;

export const StyledContainer = tw.div`
  flex items-center flex-col
`;

export const StyledDescription = tw.div`
  mb-4
`;

export const StyledList = tw.li`
  mt-4 pl-4
`;
