import { setFontWeight, theme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { lsI18NService } from '../../../../../service';
import { selectProspect, updateProspect } from '../../../../../store/modules/prospect/slice';
import { StyledActionWrapper, StyledWrapper } from './styles';
import { useApplyPromoCodeMutation } from '../../../../../store/modules/prospect/api';
import { InvalidPromoCodeError } from '../../../../../constants/GraphQLErrors';

const defaultValues = { promoCode: '' };
interface PropsModal {
  hide: () => void;
  setShowToast: (status: boolean) => void;
}

export const PromoCodeModal = (props: PropsModal) => {
  const { hide, setShowToast } = props;
  const dispatch = useDispatch();
  const { id: prospectId, promoCode } = useSelector(selectProspect);
  const [applyPromoCode, { isSuccess, isLoading, error }] = useApplyPromoCodeMutation();

  const { control, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues: { promoCode: promoCode || defaultValues.promoCode },
  });

  // Handle validation error
  useEffect(() => {
    const { message, error: invalidPromoCodeError } = InvalidPromoCodeError;
    const isInvalidPhoneNumber = error?.message?.includes(invalidPromoCodeError);

    isInvalidPhoneNumber && setError('promoCode', { type: 'error', message });
  }, [error, setError]);

  const onApply = useCallback(() => {
    setShowToast(false);

    applyPromoCode({ prospectId, promoCode: getValues().promoCode });
  }, [setShowToast, applyPromoCode, getValues, prospectId]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(updateProspect({ promoCode: getValues().promoCode }));
      setShowToast(true);
      hide();
    }
  }, [isSuccess, setShowToast, hide, dispatch, getValues]);

  const titleLabel = promoCode
    ? lsI18NService.t(`reviewAndConfirmation.promoCode.changeTitle`)
    : lsI18NService.t(`reviewAndConfirmation.promoCode.addTitle`);

  return (
    <div style={{ height: '100%', width: '100%', paddingLeft: 15, paddingRight: 15 }}>
      <StyledWrapper>
        <div
          onClick={hide}
          onKeyDown={() => ({})}
          role="button"
          tabIndex={0}
          style={{
            padding: theme.spacing.s3,
            position: 'absolute',
            marginTop: 10,
            right: 0,
            top: 0,
            zIndex: 1,
          }}
        >
          <Icon color={theme.colors.scrim} name="close" size={theme.sizing.s6} />
        </div>
        <StyledActionWrapper>
          <Text variant="headlineMedium" style={{ ...setFontWeight('600') }}>
            {titleLabel}
          </Text>
          <TextInput
            placeholder={lsI18NService.t('reviewAndConfirmation.promoCode.placeholder')}
            name="promoCode"
            testID="text-promocode"
            control={control}
            containerStyle={{ flex: 1, marginTop: 10 }}
            rules={{
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9]{4,}$/,
                message: lsI18NService.t('formErrors.invalidPromoCode'),
              },
            }}
          />
        </StyledActionWrapper>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0,
            marginBottom: 25,
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          <Button
            mode="contained"
            trackID={`apply-promocode`}
            style={{ marginTop: 2 }}
            onPress={onApply}
            disabled={isLoading || !formState.isValid}
            loading={isLoading}
          >
            {lsI18NService.t('reviewAndConfirmation.promoCode.apply')}
          </Button>
        </div>
      </StyledWrapper>
    </div>
  );
};
