import tw from 'tailwind-styled-components';
import { styleTitle } from '../../../../../components/StepTitle/styles';

export const StyledTitleWrapper = tw.div`
  block w-full md:text-left text-center md:mt-2 py-2
`;

export const StyledSubTitleWrapper = tw.div`
  block w-full md:text-left text-center pb-6`;

export const styleAccordion = { titleText: { width: '100%', justifyContent: 'space-between' } };

export const styleCustomTitle = {
  ...styleTitle,
  textAlign: 'center',
};
