import { To, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { Step } from '../../../layout/Step';
import { StepTitle } from '../../../components/StepTitle';
import { lsI18NService } from '../../../service';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { StepId } from '../../../enums/schema';

import { TreatmentCarousel } from './components/TreatmentCarousel';
import { TreatmentTable } from './components/TreatmentTable';
import { TreatmentSummary } from './components/TreatmentSummary';
import { PriceSummary } from './components/PriceSummary';
import { centsToDollar } from '../../../helpers/currency';
import { Faq } from './components/Faq';
import { CompactFaq } from './components/CompactFaq';
import { useCallback, useEffect, useMemo } from 'react';
import { removeCartItem, selectServices } from '../../../store/modules/cart/slice';
import { Service } from '../../../enums/service';

const stepId = StepId.LawnTreatment;

export const LawnTreatment = () => {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goToNextStep } = useStepNavigation({ stepId });
  const { items } = useSelector(selectServices);

  const fertilization = useMemo(() => items.find((item) => item.type === Service.Fertilization), [items]);

  const nextStep = useCallback(() => void navigate(goToNextStep as To), [navigate, goToNextStep]);

  useEffect(() => {
    if (!fertilization) {
      nextStep();
    }
  }, [fertilization, nextStep]);

  const onSkipQuote = () => {
    fertilization && dispatch(removeCartItem({ estimateId: fertilization.estimateId }));
    nextStep();
  };

  return (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} titleSlot={() => <CompactFaq />} />
          <TreatmentSummary />
          <TreatmentTable />
          <TreatmentCarousel />
        </Step.Content>

        <Step.Form className="md:mb-8">
          <Step.Footer className="md:rounded-lg md:border md:py-8 border-graySecondary">
            <PriceSummary price={centsToDollar(fertilization?.amount)} times="7-8" />
            <Step.Action className="flex flex-col gap-2 mb-2 md:my-4">
              <Button onPress={() => nextStep()} trackID="book-today-cta" testID="book-today-btn" mode="contained">
                {lsI18NService.t('lawnTreatment.bookFor0')}
              </Button>
              <Button
                onPress={() => onSkipQuote()}
                mode="contained-tonal"
                trackID="skip-quote-cta"
                testID="skip-quote-btn"
              >
                {lsI18NService.t('lawnTreatment.skipThisQuote')}
              </Button>
            </Step.Action>
            <Text variant="bodyMedium" style={{ color: theme.colors.outline }}>
              {lsI18NService.t('lawnTreatment.willBeChargedLater')}
            </Text>
          </Step.Footer>
          <Faq />
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
