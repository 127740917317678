import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectContact } from '../../../store/modules/contact/slice';
import { useCreateContactMutation } from '../../../store/modules/contact/api';
import { EmailAlreadyTakenError } from '../../../constants/GraphQLErrors';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

export const useAccountAndPayment = () => {
  const { hashId: prospectId } = useParams();
  const [createContact, { data, isSuccess, isLoading, error }] = useCreateContactMutation();
  const { name, phone } = useSelector(selectContact);

  const fullName = name?.split(' ');

  const { control, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      firstName: fullName?.[0] || defaultValues.firstName,
      lastName: fullName?.[1] || defaultValues.lastName,
    },
  });
  const [isEmailAlreadyTaken, setIsEmailAlreadyTaken] = useState(false);

  // Handle validation errors
  useEffect(() => {
    const { message, error: emailError } = EmailAlreadyTakenError;

    if (error?.message?.includes(emailError)) {
      setIsEmailAlreadyTaken(true);
      setError('email', { type: 'error', message });
    }
  }, [error, setError]);

  const handleSubmit = () => {
    const { firstName, lastName, email, password } = getValues();

    const contactPayload = {
      name: `${firstName} ${lastName}`,
      phone,
      email: email,
      password: password,
      prospectId,
    };
    createContact(contactPayload);
  };

  return {
    handleSubmit,
    form: {
      control,
      formState,
      email: getValues().email,
      password: getValues().password,
    },
    mutation: {
      data,
      isLoading,
      isSuccess,
      errors: { isEmailAlreadyTaken },
    },
  };
};
