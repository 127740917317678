import { Text } from '@lawnstarter/ls-react-common/atoms';
import { StyledPriceTitleWrapper } from './styles';
import { lsI18NService } from '../../../../../service';
import { styleTitle } from '../../../../../components/StepTitle/styles';
import { CompactFaq } from '../CompactFaq';

interface PriceProps {
  price: string;
  frequency: string;
  className?: string;
}

export const PriceTitle = ({ price, frequency, className = 'md:block hidden' }: PriceProps) => {
  return (
    <StyledPriceTitleWrapper className={className}>
      <Text variant="headlineLarge" style={styleTitle}>
        {lsI18NService.t('scheduling.priceTitle', { price, frequency })}
        <CompactFaq />
      </Text>
    </StyledPriceTitleWrapper>
  );
};
