export enum Service {
  // LawnTreatment
  Fertilization = 'fertilization',
  // ExtraServices
  BushTrimming = 'bushtrimming',
  TreeCare = 'treecare',
  Cleanups = 'cleanup_spring',
  LeafRemoval = 'cleanup_leaves',
  Landscaping = 'landscaping',
  Other = 'other',
}
