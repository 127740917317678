import React from 'react';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';

import { VITE_BRAND } from '../config/env';

export function useDynamicSvgImport(name: string, isBranded: boolean = true) {
  const importedIconRef = useRef<React.FC<React.SVGProps<SVGElement>>>(() => <></>);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    setLoading(true);
    const importSvgIcon = async (): Promise<void> => {
      try {
        importedIconRef.current = (
          await import(isBranded ? `../assets/${VITE_BRAND}/${name}.svg` : `../assets/${name}.svg`)
        ).ReactComponent;
      } catch (err) {
        setError(err);
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    };

    importSvgIcon();
  }, [name, isBranded]);

  return { error, loading, Svg: importedIconRef.current };
}
