import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../..';
import { Nullable } from '@lawnstarter/ls-react-common/types';

type NullableString = Nullable<string>;
export interface ContactState {
  id: NullableString;
  name: NullableString;
  phone: NullableString;
  email: NullableString;
}

export const initialState: ContactState = {
  id: null,
  name: null,
  phone: null,
  email: null,
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    updateContact(state, { payload }: PayloadAction<Partial<ContactState>>) {
      return {
        ...state,
        ...payload,
      };
    },
    resetContact: () => initialState,
  },
});

export const { updateContact, resetContact } = slice.actions;

export default slice.reducer;

export const selectContact = (state: RootState) => state.contact;
