import tw from 'tailwind-styled-components';

export const StyledFaqWrapper = tw.div`
  hidden md:block mt-10
`;

export const StyledCard = tw.div`
  flex flex-col md:p-6 md:border border-graySecondary rounded-[10px] gap-y-[8px] md:gap-y-[19px]
`;

export const CardSection = tw.div`
  flex-1 flex-line flex flex-col gap-y-[12px]
`;

export const CardSectionLine = tw.div`
  flex flex-line items-center justify-between
`;

export const StyledLink = tw.span`
  underline text-primary text-sm font-inter cursor-pointer
`;

export const StyledIcon = tw.div`
  flex flex-line items-center
`;

export const PromoCodeSection = tw.div`
  w-full flex justify-between mb-8 md:mb-12
`;
