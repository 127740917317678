import { setFontWeight } from '@lawnstarter/ls-react-common';
import tw from 'tailwind-styled-components';

export const StyledBody = tw.div`
  StyledBody flex flex-col items-center m-auto gap-y-4
`;

export const StyledContent = tw.div`
  text-center w-full
`;

export const titleStyle = {
  ...setFontWeight('600'),
};
