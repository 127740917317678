import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../..';
import { Nullable } from '@lawnstarter/ls-react-common/types';

type NullableString = Nullable<string>;
export interface PropertyState {
  name: NullableString;
  address1: NullableString;
  address2?: NullableString;
  city: NullableString;
  state: NullableString;
  country: NullableString;
  zip: NullableString;
  phone: NullableString;
}

export const initialState: PropertyState = {
  name: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  country: null,
  zip: null,
  phone: null,
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    updateProperty(state, { payload }: PayloadAction<Partial<PropertyState>>) {
      return {
        ...state,
        ...payload,
      };
    },
    resetProperty: () => initialState,
  },
});

export const { updateProperty, resetProperty } = slice.actions;

export default slice.reducer;

export const selectProperty = (state: RootState) => state.property;
