import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetProspectQuery, useSubmitProspectAsOrderMutation } from '../../../store/modules/prospect/api';
import { useSelector } from 'react-redux';
import { selectProspect } from '../../../store/modules/prospect/slice';
import { selectProperty } from '../../../store/modules/property/slice';

export const useReviewAndConfirmation = () => {
  const { hashId } = useParams();
  const [submitProspectAsOrder, { data, isSuccess, isUninitialized }] = useSubmitProspectAsOrderMutation();

  const prospect = useSelector(selectProspect);
  const property = useSelector(selectProperty);
  const [getProspectQuery] = useLazyGetProspectQuery();

  useEffect(() => {
    const authToken = data?.submitProspectAsOrder?.authToken;

    if (isSuccess && authToken) {
      sessionStorage.setItem('authToken', authToken as string);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    !prospect.services && getProspectQuery({ id: hashId });
  }, [getProspectQuery, hashId, prospect.services]);

  const handleSubmit = useCallback(() => {
    submitProspectAsOrder({ prospectId: prospect.id });
  }, [prospect.id, submitProspectAsOrder]);

  const isReadyToBook = useMemo(
    () => Boolean(prospect.lsApiPropertyId && prospect.paymentLastFour),
    [prospect.lsApiPropertyId, prospect.paymentLastFour],
  );

  return {
    handleSubmit,
    prospect,
    isReadyToBook,
    property,
    mutation: {
      data,
      isUninitialized,
      isSuccess,
    },
  };
};
