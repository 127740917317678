import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';

import { StyledSubTitleWrapper, StyledTitleWrapper, styleCustomTitle } from './styles';
import { lsI18NService } from '../../../../../service';
import { useSchema } from '../../../../../hooks/useSchema';
import { PriceTitle } from '../PriceTitle';
import { StyledWrapper, styleSubTitle } from '../../../../../components/StepTitle/styles';
import { StyledFaqWrapper } from '../../styles';
import './style.css';
import { METADATA } from '../../../../../constants/metadata';
import { VITE_BRAND } from '../../../../../config/env';
import { Faq } from '../Faq';

const SOCIAL_PROOF_BASE_VALUE = 40;

interface HeaderProps {
  price: string;
  frequency: string;
  zip: string;
}

export const Header = ({ price, frequency, zip }: HeaderProps) => {
  const theme = useAppTheme();
  const { vertical } = useSchema();
  const { displayName } = METADATA[VITE_BRAND as Brand];
  const lastDigitOfZip = parseInt(zip, 10) % 10;
  const socialProof = lastDigitOfZip ? lastDigitOfZip + SOCIAL_PROOF_BASE_VALUE : SOCIAL_PROOF_BASE_VALUE;

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <Text variant="headlineMedium" style={styleCustomTitle}>
          {lsI18NService.t('scheduling.title', { vertical })}
        </Text>
      </StyledTitleWrapper>

      <PriceTitle price={price} frequency={frequency} className="md:hidden" />

      <StyledSubTitleWrapper>
        <Text variant="titleMedium" style={{ ...styleSubTitle, color: theme.colors.primary }}>
          {lsI18NService.t('scheduling.socialProof', { socialProof })}
        </Text>
        <Text variant="titleMedium" style={styleSubTitle}>
          {lsI18NService.t('scheduling.description', { zip, brand: displayName })}
        </Text>
      </StyledSubTitleWrapper>
      <StyledFaqWrapper className="hidden md:flex">
        <Faq />
      </StyledFaqWrapper>
    </StyledWrapper>
  );
};
