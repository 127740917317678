import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../..';
import { Prospect } from '../../../types/graphql';
import { Vertical } from '../../../enums/schema';

export const initialState: Partial<Prospect> = {
  id: null,
};

const slice = createSlice({
  name: 'prospect',
  initialState,
  reducers: {
    updateProspect(state, { payload }: PayloadAction<Partial<Prospect>>) {
      return {
        ...state,
        ...payload,
      };
    },
    resetProspect: () => initialState,
  },
});

export const { updateProspect, resetProspect } = slice.actions;

export default slice.reducer;

export const selectProspect = (state: RootState): Prospect => state.prospect;

export const selectEstimatesByVertical = (state: RootState, vertical: Vertical) => {
  const { services }: Prospect = state.prospect;
  return services?.find((service) => service.type === vertical)?.estimates || [];
};
