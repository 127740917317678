import tw from 'tailwind-styled-components';

export const StyledWrapper = tw.button`
  flex place-center
`;

export const styleLabel = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};
