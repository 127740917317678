import { lsI18NService } from '../../../service';

export const TERMS_OF_SERVICE = [
  {
    title: lsI18NService.t('termsOfService.terms.cancellationPeriod.title'),
    value: 'cancellationPeriod',
    content: [
      lsI18NService.t('termsOfService.terms.cancellationPeriod.content.firstLine'),
      lsI18NService.t('termsOfService.terms.cancellationPeriod.content.secondLine'),
    ],
  },
  {
    title: lsI18NService.t('termsOfService.terms.longGrassFee.title'),
    value: 'longGrassFee',
    content: [
      lsI18NService.t('termsOfService.terms.longGrassFee.content.firstLine'),
      lsI18NService.t('termsOfService.terms.longGrassFee.content.secondLine'),
    ],
  },
  {
    title: lsI18NService.t('termsOfService.terms.cutMinimum.title'),
    value: 'cutMinimum',
    content: [
      lsI18NService.t('termsOfService.terms.cutMinimum.content.firstLine'),
      lsI18NService.t('termsOfService.terms.cutMinimum.content.secondLine'),
    ],
  },
];
